import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import { FaLinkedin } from "react-icons/fa";

const SiteFooter = () => {
  return (
    <footer className="footer">
      <Container className="d-none d-sm-block">
        <Row>
          <Col md={6}>
            <div className="footer-about">
              <h3 className="footer-about-title">
                VuProp - The Virtual Tour Specialists
              </h3>
              <div className="footer-about-text">
                Specialists in creating fully immersive virtual tours of
                aircraft to aid in the auditing and marketing through VuProp's
                360 solution
              </div>
            </div>
          </Col>

          <Col md={3}>
            <h3 className="footer-about-title">Company</h3>
            {/* <a
              href="https://vuprop.com/about"
              target="_blank"
              className="no-decoration"
            >
              <div className="footer-link">About</div>
            </a>
            <a
              href="https://vuprop.com/services"
              target="_blank"
              className="no-decoration"
            >
              <div className="footer-link">Services</div>
            </a> */}
            <a
              href="https://vuprop.com/contact"
              target="_blank"
              className="no-decoration"
            >
              <div className="footer-link">Contact</div>
            </a>
          </Col>

          <Col md={3}>
            <h3 className="footer-about-title">Contact</h3>
            <div>
              <a
                href="mailto: ryan@vuprop.com"
                target="_blank"
                className="no-decoration"
              >
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <span className="material-symbols-outlined footer-icon">
                    email
                  </span>
                  <div className="footer-about-text">ryan@vuprop.com</div>
                </Stack>
              </a>
              <a
                href="https://www.linkedin.com/company/78439996/admin/"
                target="_blank"
                className="no-decoration"
              >
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <FaLinkedin className="footer-icon" />
                  <div className="footer-about-text">VuProp</div>
                </Stack>
              </a>
              <a>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <span className="material-symbols-outlined footer-icon">
                    pin_drop
                  </span>
                  <div className="footer-about-text">Dublin, Ireland</div>
                </Stack>
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Mobile Stuff */}

      <Container className="d-block d-sm-none">
        <Row>
          <Col xs={12}>
            <div className="footer-about-mobile">
              <h3 className="footer-about-title-mobile">
                VuProp - The Virtual Tour Specialists
              </h3>
              <div className="footer-about-text-mobile">
                Specialists in creating fully immersive virtual tours of
                aircraft to aid in the auditing and marketing through VuProp's
                360 solution
              </div>
            </div>
          </Col>

          <Col xs={6}>
            <h3 className="footer-about-title">Contact</h3>
            <div>
              <a
                href="mailto: ryan@vuprop.com"
                target="_blank"
                className="no-decoration"
              >
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <span className="material-symbols-outlined footer-icon">
                    email
                  </span>
                  <div className="footer-about-text">ryan@vuprop.com</div>
                </Stack>
              </a>
              <a
                href="https://www.linkedin.com/company/78439996/admin/"
                target="_blank"
                className="no-decoration"
              >
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <FaLinkedin className="footer-icon" />
                  <div className="footer-about-text">VuProp</div>
                </Stack>
              </a>
              <a>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <span className="material-symbols-outlined footer-icon">
                    pin_drop
                  </span>
                  <div className="footer-about-text">Dublin, Ireland</div>
                </Stack>
              </a>
            </div>
          </Col>
          <Col xs={6}>
            <h3 className="footer-about-title">Company</h3>
            <a
              href="https://vuprop.com/about"
              target="_blank"
              className="no-decoration"
            >
              <div className="footer-link">About</div>
            </a>
            <a
              href="https://vuprop.com/services"
              target="_blank"
              className="no-decoration"
            >
              <div className="footer-link">Services</div>
            </a>
            <a
              href="https://vuprop.com/contact"
              target="_blank"
              className="no-decoration"
            >
              <div className="footer-link">Contact</div>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default SiteFooter;
