import { Container } from "react-bootstrap";

const AboutPage = () => {
  return (
    <Container>
      <h1>About</h1>
    </Container>
  );
};

export default AboutPage;
