import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SiteFooter from "./SiteFooter";
import SiteNavbar from "./SiteNavbar";

const Layout = () => {
  return (
    <div>
      <SiteNavbar />
      <Outlet />
      <SiteFooter />
    </div>
  );
};

export default Layout;
