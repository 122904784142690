// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { addDoc, collection, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCOhWgSt3Y3x-zgwP3S48sBtnK223VzesM",

  authDomain: "vuprop-a0b9f.firebaseapp.com",

  projectId: "vuprop-a0b9f",

  storageBucket: "vuprop-a0b9f.appspot.com",

  messagingSenderId: "84934060029",

  appId: "1:84934060029:web:1bd2790d40f1d6946fdb94",

  measurementId: "G-R03BPJYN25",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const firestoreDb = getFirestore(app);

export async function sendContactEmail(request) {
  try {
    let query = collection(firestoreDb, "mail");
    // TODO add check for tours created by someone else
    let docRef = await addDoc(query, {
      to: "ryan@vuprop.com",
      message: {
        subject: `Form Contact from ${request.email}`,
        html: `Hi,
        <br/>
        <br/>
        ${request.name} has send a message using the VuProp contact form.
        <br/>
        <br/>
        Contact Name: ${request.name}.
        <br/>
        <br/>
        Contact Email: ${request.email}.
        <br/>
        <br/>
        Message: ${request.message}
        <br/>
        <br/>
        This in an automated email. Please respond to the contact email instead.`,
      },
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
