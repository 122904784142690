import { Container } from "react-bootstrap";
import ContactSegment from "../components/ContactSegment";
import LandingSegment from "../components/LandingSegment";
import VuPropProcessSegment from "../components/VuPropProcessSegment";
import WhyVirtualToursSegment from "../components/WhyVirtualToursSegment";

const HomePage = () => {
  return (
    <div>
      <LandingSegment />
      <WhyVirtualToursSegment />
      <VuPropProcessSegment />
      <ContactSegment />
    </div>
  );
};

export default HomePage;
