import { Container } from "react-bootstrap";

const ServicesPage = () => {
  return (
    <Container>
      <h1>Services</h1>
    </Container>
  );
};

export default ServicesPage;
