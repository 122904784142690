import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SiteNavbar from "./SiteNavbar";

const VuPropProcessSegment = () => {
  return (
    <div>
      <div className="vuprop-process-segment-container d-none d-sm-block">
        <div className="vuprop-process-segment-background"></div>
        <div className="process-header-container">
          <br />
          <br />
          <h2 className="segment-header-title">The VuProp Process</h2>
        </div>
        <Container>
          <Row>
            <Col md={4}>
              <h2 className="process-title">Setup</h2>
            </Col>

            <Col md={8} className="m-0">
              <img
                src="images/setup-process.png"
                className="process-image"
              ></img>
            </Col>

            <Col md={4}>
              <h2 className="process-title-2">Virtual Tour</h2>
            </Col>

            <Col md={8}>
              <img
                src="images/tour-process.png"
                className="process-image-larger"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="d-block d-sm-none">
        <div className="process-header-container-mobile">
          <h2 className="segment-header-title-mobile">The VuProp Process</h2>
        </div>
        <Container>
          <h2 className="process-title-mobile">Setup</h2>
          <div>
            <img
              src="images/setup-process-mobile.png"
              className="process-image-mobile"
            ></img>
          </div>
          <h2 className="process-title-mobile">Virtual Tour</h2>
          <div>
            <img
              src="images/tour-process-mobile.png"
              className="process-image-larger-mobile"
            ></img>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default VuPropProcessSegment;
