import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SiteNavbar from "./SiteNavbar";

const WhyVirtualToursSegment = () => {
  return (
    <div>
      <Container className="page-segment d-none d-sm-block">
        <div className="segment-header-container">
          <h2 className="segment-header-title">Why Virtual Tours</h2>
          <div className="segment-header-text">
            Virtual tours offer a compelling and efficient way to market
            aircraft to prospective clients, providing an immersive experience,
            time and cost savings. Clients or consultants can conveniently view
            and evaluate the aircraft from anywhere around the world and see the
            aircraft in a transparent environment.
          </div>
        </div>
        <Row className="about-card-row">
          <Col md={4}>
            <div className="about-card">
              <h4 className="about-card-title">Virtual Experience</h4>
              <img
                src="/images/virtual-tour-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Immersive virtual experience prospective clients and consultants
                to view and navigate the aircraft with more efficiency
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card">
              <h4 className="about-card-title">Competitive Advantage</h4>
              <img
                src="/images/competitive-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Offering virtual tours sets aircraft leasing companies apart
                from competitors who rely solely on traditional marketing
                approaches.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card">
              <h4 className="about-card-title">Sustainability</h4>
              <img
                src="/images/sustainability-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                By reducing the need for physical visits, virtual tours
                contribute to sustainability efforts by minimizing carbon
                emissions associated with travel.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card">
              <h4 className="about-card-title">Historical Record</h4>
              <img
                src="/images/historical-record-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Multiple virtual tours of a single aircraft allow an historical
                record of the aircraft's condition over it's life cycle.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card">
              <h4 className="about-card-title">Speed</h4>
              <img
                src="/images/camera-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                It takes 15 to 25 minutes to capture images of the aircraft
                using the VuProp equipment.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card">
              <h4 className="about-card-title">Documentation</h4>
              <img
                src="/images/documentation-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Upload documentation associated with the aircraft in the VuProp
                Software.
              </div>
            </div>
          </Col>
        </Row>
        <div className="about-button-container">
          <a
            href="https://embed.vuprop.com/tour/2a829f52-f017-488e-b82c-6d0e223597eb"
            target="_blank"
          >
            <Button className="about-button">Explore an Airbus A320</Button>
          </a>
        </div>
      </Container>

      {/* Mobile Stuff */}

      <Container className="d-block d-sm-none">
        <div className="segment-header-container-mobile">
          <h2 className="segment-header-title-mobile">Why Virtual Tours</h2>
          <div className="segment-header-text-mobile">
            Virtual tours offer a compelling and efficient way to market
            aircraft to prospective clients, providing an immersive experience,
            time and cost savings. Clients or consultants can conveniently view
            and evaluate the aircraft from anywhere around the world and see the
            aircraft in a transparent environment.
          </div>
        </div>

        <Row className="about-card-row-mobile">
          <Col md={4}>
            <div className="about-card-mobile">
              <h4 className="about-card-title">Virtual Experience</h4>
              <img
                src="/images/virtual-tour-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Immersive virtual experience prospective clients and consultants
                to view and navigate the aircraft with more efficiency
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card-mobile">
              <h4 className="about-card-title">Competitive Advantage</h4>
              <img
                src="/images/competitive-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Offering virtual tours sets aircraft leasing companies apart
                from competitors who rely solely on traditional marketing
                approaches.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card-mobile">
              <h4 className="about-card-title">Sustainability</h4>
              <img
                src="/images/sustainability-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                By reducing the need for physical visits, virtual tours
                contribute to sustainability efforts by minimizing carbon
                emissions associated with travel.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card-mobile">
              <h4 className="about-card-title">Historical Record</h4>
              <img
                src="/images/historical-record-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Multiple virtual tours of a single aircraft allow an historical
                record of the aircraft's condition over it's life cycle.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card-mobile">
              <h4 className="about-card-title">Speed</h4>
              <img
                src="/images/camera-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                It takes 15 to 25 minutes to capture images of the aircraft
                using the VuProp equipment.
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-card-mobile">
              <h4 className="about-card-title">Documentation</h4>
              <img
                src="/images/documentation-icon.png"
                className="about-card-image"
              ></img>
              <div className="about-card-text">
                Upload documentation associated with the aircraft in the VuProp
                Software.
              </div>
            </div>
          </Col>
        </Row>
        <div className="about-button-container-mobile">
          <a
            href="https://embed.vuprop.com/tour/2a829f52-f017-488e-b82c-6d0e223597eb"
            target="_blank"
          >
            <Button className="about-button">Explore an Airbus A320</Button>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default WhyVirtualToursSegment;
