import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";

const SiteNavbar = () => {
  return (
    <Navbar expand="lg" variant="dark" bg="black">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={logo}
            width="150"
            height="50"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <span className="material-symbols-outlined white-icon">menu</span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="justify-content-end align-content-center">
            {/* <Nav.Link as={Link} to="/services" className="m-auto navbar-text">
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="m-auto navbar-text">
              About
            </Nav.Link> */}
            <Nav.Link as={Link} to="/contact-us" className="m-auto navbar-text">
              Contact
            </Nav.Link>
            <Nav.Link href="https://app.vuprop.com" className="m-auto ">
              <Button variant="light" className="navbar-button">
                Login
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default SiteNavbar;
