import { Button, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SiteNavbar from "./SiteNavbar";

const LandingSegment = () => {
  return (
    <div className="landing-segment-container">
      <div className="landing-segment-background d-none d-sm-block"></div>
      <Container className="d-none d-sm-block">
        <Row className="vh-100 m-0">
          <Col md={6} className="landing-segment-hero">
            <div className="landing-segment-hero-inner">
              <h1 className="landing-segment-title">
                The first step is virtual.
              </h1>
              <div className="landing-segment-text">
                Revolutionizing your fleet management through cutting-edge
                virtual tour technology. With our innovative solutions, we
                empower aircraft owners, operators, and sellers to showcase
                their assets like never before.
              </div>
              <a
                href="https://embed.vuprop.com/tour/2a829f52-f017-488e-b82c-6d0e223597eb"
                target="_blank"
              >
                <Button className="hero-button">Explore an Airbus A320</Button>
              </a>
            </div>
          </Col>
          <Col md={6} className="landing-segment-image-container">
            <img
              src="/images/landing-segment-image.png"
              className="landing-segment-image"
            />
          </Col>
        </Row>
      </Container>
      <Container className="d-block d-sm-none">
        <div className="landing-segment-hero-inner-mobile">
          <h1 className="landing-segment-title-mobile">
            The first step is virtual.
          </h1>
          <div className="landing-segment-text-mobile">
            Revolutionizing your fleet management through cutting-edge virtual
            tour technology. With our innovative solutions, we empower aircraft
            owners, operators, and sellers to showcase their assets like never
            before.
          </div>
          <img
            src="/images/landing-segment-image.png"
            className="landing-segment-image-mobile"
          />
          <a
            href="https://embed.vuprop.com/tour/2a829f52-f017-488e-b82c-6d0e223597eb"
            target="_blank"
          >
            <Button className="hero-button">Explore an Airbus A320</Button>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default LandingSegment;
