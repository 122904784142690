import { Container } from "react-bootstrap";

const ContactPage = () => {
  return (
    <Container>
      <h1>Contact Us</h1>
    </Container>
  );
};

export default ContactPage;
