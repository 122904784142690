import { useState } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { sendContactEmail } from "../firebase";
import SiteNavbar from "./SiteNavbar";

const ContactSegment = () => {
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [emailSend, setEmailSent] = useState(false);

  const contact = async () => {
    if (formEmail === "" || formName === "" || formMessage === "") {
      return;
    }

    const request = {
      name: formName,
      email: formEmail,
      message: formMessage,
    };
    await sendContactEmail(request);
    setEmailSent(true);
  };

  return (
    <div className="page-segment">
      <Container className="d-none d-sm-block">
        <Row>
          <Col md={6}>
            <h3 className="contact-segment-title">Contact Us</h3>
            <div className="contact-segment-icons-container">
              <a
                href="mailto: ryan@vuprop.com"
                target="_blank"
                className="no-decoration"
              >
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <span className="material-symbols-outlined contact-icon">
                    email
                  </span>
                  <div className="contact-text">ryan@vuprop.com</div>
                </Stack>
              </a>
              <a
                href="https://www.linkedin.com/company/78439996/admin/"
                target="_blank"
                className="no-decoration"
              >
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <FaLinkedin className="contact-icon" />
                  <div className="contact-text">VuProp</div>
                </Stack>
              </a>
              <a>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="footer-icon-container"
                >
                  <span className="material-symbols-outlined contact-icon">
                    pin_drop
                  </span>
                  <div className="contact-text">Dublin, Ireland</div>
                </Stack>
              </a>
            </div>
          </Col>
          <Col md={6}>
            <div className="contact-form">
              <Form>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    onChange={(event) => setFormName(event.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={(event) => setFormEmail(event.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(event) => setFormMessage(event.target.value)}
                  />
                </Form.Group>
                {emailSend ? (
                  <div className="form-sent-text">
                    Thank you! We will be in contact soon.
                  </div>
                ) : (
                  <Button className="submit-button" onClick={() => contact()}>
                    Submit
                  </Button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
        <br />
        <br />
      </Container>

      {/* Mobile Stuff */}

      <Container className="d-block d-sm-none">
        <div className="process-header-container-mobile">
          <h2 className="segment-header-title-mobile">Contact Us</h2>
        </div>
        <div className="contact-segment-icons-container">
          <a
            href="mailto: ryan@vuprop.com"
            target="_blank"
            className="no-decoration"
          >
            <Stack
              direction="horizontal"
              gap={2}
              className="footer-icon-container"
            >
              <span className="material-symbols-outlined contact-icon">
                email
              </span>
              <div className="contact-text">ryan@vuprop.com</div>
            </Stack>
          </a>
          <a
            href="https://www.linkedin.com/company/78439996/admin/"
            target="_blank"
            className="no-decoration"
          >
            <Stack
              direction="horizontal"
              gap={2}
              className="footer-icon-container"
            >
              <FaLinkedin className="contact-icon" />
              <div className="contact-text">VuProp</div>
            </Stack>
          </a>
          <a>
            <Stack
              direction="horizontal"
              gap={2}
              className="footer-icon-container"
            >
              <span className="material-symbols-outlined contact-icon">
                pin_drop
              </span>
              <div className="contact-text">Dublin, Ireland</div>
            </Stack>
          </a>
        </div>
        <div className="contact-form-mobile">
          <Form>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                onChange={(event) => setFormName(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(event) => setFormEmail(event.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(event) => setFormMessage(event.target.value)}
              />
            </Form.Group>
            <div className="">
              {emailSend ? (
                <div className="form-sent-text">
                  Thank you! We will be in contact soon.
                </div>
              ) : (
                <Button className="submit-button" onClick={() => contact()}>
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </div>
        <br />
        <br />
      </Container>
    </div>
  );
};

export default ContactSegment;
